import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import first from 'lodash/first'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import flatten from 'lodash/flatten'
import { Badge, Button, Col, Spinner, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts'

import Activity from '../components/sales/activity'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import Profitability from '../components/sales/profitability'
import { Row, Table, NarrowCell, defaultColumn, HeaderCell, Header, isColumn } from '../components/common/Table'
import { fetchSalesDataAction, organizationsSelector, OrganizationsState } from '../hasura/slices/organizations'
import { statTypeDescription } from '../lib/pricingHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  SalesData_organizations_dicom_servers_cases,
  SalesData_organizations_distribution_emails,
} from '../hasura/graphQlQueries/types/SalesData'

interface UserBucket {
  name: string
  fits: (data: OrganizationSalesData) => boolean
  fitsDescription: (t: TimeFrame) => string
}

const USER_BUCKETS: UserBucket[] = [
  {
    name: 'Active',
    fits: (data: OrganizationSalesData) => data.casesWithConsults.length > 0,
    fitsDescription: (t: TimeFrame) => `Had a consultation in the last ${t}`,
  },
  {
    name: 'New',
    fits: (data: OrganizationSalesData) => moment(data.createdAt).isAfter(moment().subtract(3, 'month')),
    fitsDescription: (t: TimeFrame) => `Created in the last ${t}`,
  },
  {
    name: 'Inactive',
    fits: (data: OrganizationSalesData) => data.casesWithConsults.length === 0,
    fitsDescription: (t: TimeFrame) => `No consultations in the last ${t}`,
  },
]

interface OrganizationSalesData {
  aiReportInteractionsOneMonth: number
  aiReportViewsOneMonth: number
  viewerViewsOneMonth: number
  aiReportInteractionsThreeMonths: number
  aiReportViewsThreeMonths: number
  viewerViewsThreeMonths: number
  cases: SalesData_organizations_dicom_servers_cases[]
  casesWithConsults: SalesData_organizations_dicom_servers_cases[]
  createdAt: string
  displayName: string
  hasDicomServer: boolean
  consultsCount: number
  people: SalesData_organizations_distribution_emails[]
  primaryEmail: string
  products: [string, number][]
}

enum TimeFrame {
  OneMonth = '1 Month',
  ThreeMonth = '3 Months',
}

// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <p
        style={{ marginTop: '35px' }}
        className="bg--white p-1 text-s single-line"
      >{`${payload[0].payload.consults} consults week of ${payload[0].payload.name}`}</p>
    )
  }

  return null
}

const TIME_FRAMES = [TimeFrame.OneMonth, TimeFrame.ThreeMonth]

export default function Sales() {
  const dispatch = useDispatch()

  const { accessToken, defaultTurnaroundTime }: UsersState = useSelector(usersSelector)
  const { organizationsSalesData }: OrganizationsState = useSelector(organizationsSelector)

  const [activeTab, setActiveTab] = useState('activity')
  const [modifiedSalesData, setModifiedSalesData] = React.useState<OrganizationSalesData[]>([])
  const [selectedUserBucket, setSelectedUserBucket] = React.useState<UserBucket>(USER_BUCKETS[0])
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState<TimeFrame>(TimeFrame.OneMonth)

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchSalesDataAction(accessToken))
  }, [accessToken])

  useEffect(() => {
    if (!organizationsSalesData.length) return

    setModifiedSalesData(
      // @ts-ignore
      organizationsSalesData
        .map(
          (o): OrganizationSalesData => {
            const cases = flatten(o.dicom_servers.map((d) => d.cases)).filter((d) =>
              moment(d.created_at).isAfter(moment().subtract(parseInt(selectedTimeFrame, 10), 'month'))
            )
            const casesWithConsults = cases.filter((c) => c.consultations.length > 0)
            return {
              displayName: o.display_name,
              primaryEmail: o.primary_email,
              createdAt: o.created_at,
              cases,
              casesWithConsults,
              consultsCount: casesWithConsults.length,
              aiReportViewsOneMonth: o.ai_views_one_month.aggregate?.count || 0,
              aiReportInteractionsOneMonth: o.ai_interactions_one_month.aggregate?.count || 0,
              viewerViewsOneMonth: o.viewer_views_one_month.aggregate?.count || 0,
              aiReportViewsThreeMonths: o.ai_views_three_months.aggregate?.count || 0,
              aiReportInteractionsThreeMonths: o.ai_interactions_three_months.aggregate?.count || 0,
              viewerViewsThreeMonths: o.viewer_views_three_months.aggregate?.count || 0,
              hasDicomServer: o.dicom_servers.length > 0,
              people: o.distribution_emails,
              products: o.price_group
                .filter((p) => !p.addon_type && !p.addon_id && !p.is_exotic)
                .map((p) => [
                  `${statTypeDescription(defaultTurnaroundTime, p.stat_type, p.is_exotic)}${
                    p.consultation_type_id === 2 ? ' US' : ''
                  }`,
                  p.amount,
                ]),
            }
          }
        )
        .filter((m) => (selectedUserBucket ? selectedUserBucket.fits(m) : true))
    )
  }, [organizationsSalesData, selectedUserBucket, selectedTimeFrame])

  const columns = useMemo(
    () => [
      {
        Header: 'Created',
        accessor: (s: OrganizationSalesData) => moment(s.createdAt).fromNow(),
        sortType: (a: any, b: any) => moment(a.original.createdAt).isAfter(moment(b.original.createdAt)),
        sortDescFirst: true,
      },
      {
        Header: 'STAT Options',
        accessor: (s: OrganizationSalesData) => s,
        Cell: (s: { value: OrganizationSalesData }) => (
          <p className="text-xs m-0">{s.value.products.map((p) => `$${p[1]} ${p[0]}`).join(' / ')}</p>
        ),
      },
      {
        Header: 'Practice',
        accessor: (s: OrganizationSalesData) => s,
        sortDescFirst: false,
        Cell: (s: { value: OrganizationSalesData }) => (
          <div>
            <p
              onClick={() =>
                window.open(
                  `https://app.hubspot.com/reports-dashboard/9331093/view/7881996?globalSearchQuery=${s.value.primaryEmail}`,
                  '_blank'
                )
              }
              className="text-m bold m-0 underline pointer"
            >
              {s.value.displayName}
            </p>

            <div style={{ maxHeight: '75px' }} className="text-s overflow-scroll">
              {s.value.people.map((p, idx) => (
                <p key={idx} className="m-0 text-xs">
                  {p.display_name} {<span className="text--gray6">{p.email}</span>}
                </p>
              ))}
            </div>
          </div>
        ),
      },
      {
        Header: 'OHIF Views',
        accessor: (s: OrganizationSalesData) =>
          selectedTimeFrame === TimeFrame.OneMonth ? s.viewerViewsOneMonth : s.viewerViewsThreeMonths,
        sortDescFirst: true,
      },
      {
        Header: 'IA Views',
        accessor: (s: OrganizationSalesData) =>
          selectedTimeFrame === TimeFrame.OneMonth ? s.aiReportViewsOneMonth : s.aiReportViewsThreeMonths,
        sortDescFirst: true,
      },
      {
        Header: 'IA Edits',
        accessor: (s: OrganizationSalesData) =>
          selectedTimeFrame === TimeFrame.OneMonth ? s.aiReportInteractionsOneMonth : s.aiReportInteractionsThreeMonths,
        sortDescFirst: true,
      },
      {
        Header: 'Consults',
        accessor: (s: OrganizationSalesData) => s,
        sortType: (a: any, b: any) => a.original.consultsCount - b.original.consultsCount,
        sortDescFirst: false,
        _Cell: (s: { value: OrganizationSalesData }) => {
          const groupedByWeek = groupBy(s.value.cases, (c) => `${moment(c.created_at).week()}-${moment(c.created_at).year()}`)
          const chartData =
            orderBy(Object.keys(groupedByWeek), (key: any) => moment(groupedByWeek[key][0].created_at)).map((key) => {
              const name = moment(groupedByWeek[key][0].created_at).startOf('week').format('MMM D')
              const consults = groupedByWeek[key].filter((c) => c.consultations.length).length
              return { name, consults }
            }) || []

          const consultsCount = s.value.cases.filter((c) => c.consultations.length).length
          const increase =
            chartData.length > 2 ? first(chartData)!.consults < last(chartData.slice(0, chartData.length - 1))!.consults : false

          return s.value.cases.length ? (
            <div className="d-flex align-items-center">
              <ResponsiveContainer className="max-width-150px" width="100%" height={50}>
                <LineChart data={chartData}>
                  <Line type="monotone" dataKey="consults" stroke={increase ? '#28a745' : '#d1273f'} />
                  <Tooltip
                    content={
                      // @ts-ignore
                      <CustomTooltip />
                    }
                  />
                </LineChart>
              </ResponsiveContainer>

              <Badge color={increase ? 'success' : 'danger'} className="min-width-50px ml-4">
                {consultsCount}
              </Badge>
            </div>
          ) : null
        },

        get Cell() {
          return this._Cell
        },
        set Cell(value) {
          this._Cell = value
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: modifiedSalesData,
      defaultColumn,
      // @ts-ignore
      initialState: { sortBy: [{ id: 'Consults', desc: true }] },
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, 'Practice') || isColumn(data, 'Consults') ? 2 : 1)

  return (
    <Layout>
      <MainBox defaultPadding>
        <div>
          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink className={`pointer ${activeTab === 'activity' ? 'active' : ''}`} onClick={() => setActiveTab('activity')}>
                Activity
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`pointer ${activeTab === 'users' ? 'active' : ''}`} onClick={() => setActiveTab('users')}>
                Users
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`pointer ${activeTab === 'profitability' ? 'active' : ''}`}
                onClick={() => setActiveTab('profitability')}
              >
                Profitability
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="activity">
              <Row>
                <Activity />
              </Row>
            </TabPane>

            <TabPane tabId="users">
              <Row>
                <Col sm="12">
                  <div className="d-flex align-items-center">
                    <h4 className="bold m-0">Users</h4>

                    <div className="ml-5">
                      <div className="d-flex gap-10px">
                        {TIME_FRAMES.map((t, idx) => (
                          <Badge
                            className={`
                              text-xs border-radius-5px pointer
                              ${selectedTimeFrame === t ? 'bg--primary text--white' : 'bg--white text--primary'}
                            `}
                            key={idx}
                            onClick={() => setSelectedTimeFrame(t)}
                            outline={selectedTimeFrame === t}
                            size="sm"
                          >
                            {t}
                          </Badge>
                        ))}
                      </div>
                    </div>

                    <div className="ml-4">
                      <div className="d-flex gap-10px">
                        {USER_BUCKETS.map((bucket, idx) => (
                          <Button
                            color="primary"
                            className="min-width-80px text-xs p-1 border-radius-20px"
                            key={idx}
                            onClick={() => setSelectedUserBucket(bucket)}
                            outline={selectedUserBucket.name !== bucket.name}
                            size="sm"
                          >
                            {bucket.name}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </div>

                  <p className="text--gray6 text-m font-italic mb-2 mt-2">{selectedUserBucket.fitsDescription(selectedTimeFrame)}</p>

                  <Table className="max-width-1300px" cellSpacing={0} {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup: any, idx: number) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column: any) => {
                            return (
                              <HeaderCell
                                key={idx}
                                {...column.getHeaderProps(column.getSortByToggleProps({}))}
                                colSpan={colSpan(column)}
                              >
                                <div className={`d-flex align-items-center`}>
                                  <Header>{column.render('Header')}</Header>
                                </div>
                              </HeaderCell>
                            )
                          })}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {rows.length ? (
                        rows.map((row: any, idx: number) => {
                          prepareRow(row)

                          return (
                            <Row key={idx} {...row.getRowProps()}>
                              {row.cells.map((cell: any, idx2: number) => {
                                return (
                                  <NarrowCell colSpan={colSpan(cell)} key={idx2} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </NarrowCell>
                                )
                              })}
                            </Row>
                          )
                        })
                      ) : (
                        <Spinner color="primary" className="mt-2" />
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="profitability">
              <Row>
                <Profitability />
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </MainBox>
    </Layout>
  )
}
