import React, { useEffect, useRef, useState } from 'react'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import uniq from 'lodash/uniq'
import { Button, Col, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { usersSelector, UsersState } from '../../hasura/slices/users'
import { fetchOrganizationEventsAction, organizationsSelector, OrganizationsState } from '../../hasura/slices/organizations'
import { QueryName } from '../../hasura/queryNames'
import { downloadCsv } from '../../lib/helpers'

const COLORS = [
  '#0074D9',
  '#FF4136',
  '#2ECC40',
  '#FF851B',
  '#FFDC00',
  '#001f3f',
  '#39CCCC',
  '#7FDBFF',
  '#01FF70',
  '#F012BE',
  '#B10DC9',
  '#FF3860',
  '#3D9970',
  '#2ECC71',
  '#00B0FF',
  '#FF851B',
  '#FFDC00',
  '#0088CE',
  '#E81111',
  '#4E82D0',
  '#E58022',
  '#EF3340',
  '#F57500',
  '#FFC300',
  '#007F3D',
  '#73B500',
  '#00A2FF',
  '#D4C374',
  '#FF5D20',
  '#FFD1A3',
  '#4CC8C3',
  '#10C135',
  '#45E61F',
  '#00B0D6',
  '#FFAE4B',
  '#FFD700',
  '#5DBF00',
  '#FFE500',
  '#0092DD',
  '#FF6300',
]

export default function Activity() {
  const dispatch = useDispatch()

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { organizationEvents, isQuerying }: OrganizationsState = useSelector(organizationsSelector)

  const [colorsHash, setColorsHash] = useState<any>({})

  const fetchOrganizationEventsInterval = useRef<any | null>(null)

  const fetchData = () => dispatch(fetchOrganizationEventsAction(accessToken))

  useEffect(() => {
    if (!accessToken) return

    clearInterval(fetchOrganizationEventsInterval.current)
    fetchOrganizationEventsInterval.current = setInterval(fetchData, 15 * 1000)
    fetchData()

    return () => {
      clearInterval(fetchOrganizationEventsInterval.current)
    }
  }, [accessToken])

  useEffect(() => {
    if (!organizationEvents.length) return

    const organizationEventTypeIds = uniq(organizationEvents.map((event) => event.organization_event_type.id)).sort()
    const colorsHash: any = {}
    organizationEventTypeIds.forEach((id, idx) => (colorsHash[id] = COLORS[idx]))
    setColorsHash(colorsHash)
  }, [organizationEvents])

  const handleDownload = () =>
    downloadCsv(
      `Radimal-activity-${moment().format('l')}.csv`,
      ['date,practice,event,details'].concat(
        ...organizationEvents.map(
          (o) =>
            `${moment(o.created_at).format('h:mma MMM Do')}, ${o.organization.display_name}, ${
              o.organization_event_type.description
            }, ${!isEmpty(o.additional_data) && o.additional_data ? JSON.stringify(o.additional_data).replace(',', ';') : ''}`
        )
      )
    )

  return (
    <Col sm="12">
      <div className="mb-2 d-flex align-items-start justify-content-between">
        <div>
          <h4 className="bold m-0">Activity</h4>
          <div className="d-flex">
            <p className="text-xs text--gray5 font-italic">Displaying last 1,000 events</p>
            <Spinner
              className="transition-f ml-2"
              style={{ opacity: isQuerying[QueryName.FetchOrganizationEvents] ? 1 : 0 }}
              size="sm"
            />
          </div>
        </div>

        <Button outline color="primary" onClick={handleDownload}>
          Download
        </Button>
      </div>

      <div>
        {organizationEvents.map((event, idx) => (
          <div key={idx} className="d-flex gap-20px text-s">
            <p className="m-0 width-150px single-line">{moment(event.created_at).format('h:mma MMM Do')}</p>
            <p className="m-0 width-250px single-line">{event.organization.display_name}</p>
            <p style={{ color: colorsHash[event.organization_event_type.id] }} className="m-0 bold width-150px">
              {capitalize(event.organization_event_type.description)
                .replace(' ai ', ' AI ')
                .replace('pdf', 'PDF')
                .replace(' stat ', ' STAT ')}
            </p>
            {!isEmpty(event.additional_data) && event.additional_data !== '{}' && (
              <p className="m-0">{JSON.stringify(event.additional_data)}</p>
            )}
          </div>
        ))}
      </div>
    </Col>
  )
}
